var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-media-library"},[_vm._m(0),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.processFiles.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-5 mb-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.addMedia.folder),expression:"addMedia.folder"}],staticClass:"custom-select form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.addMedia.folder.$error,
              },attrs:{"id":"folder","name":"folder"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.addMedia, "folder", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Select Folder")]),_c('option',{attrs:{"value":"pages"}},[_vm._v("Pages")]),_c('option',{attrs:{"value":"products"}},[_vm._v("Products")]),_c('option',{attrs:{"value":"brands"}},[_vm._v("Brands")])]),(_vm.submitted && !_vm.$v.addMedia.folder.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Folder is required ")]):_vm._e()]),_c('div',{staticClass:"col-md-12"},[_c('vue-dropzone',{ref:"dropzone",class:{
                'is-invalid': _vm.submitted && _vm.$v.addMedia.file.$error,
              },attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-sending":_vm.sendImages},model:{value:(_vm.addMedia.file),callback:function ($$v) {_vm.$set(_vm.addMedia, "file", $$v)},expression:"addMedia.file"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('svg',{staticClass:"feather feather-upload",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"50","height":"50","viewBox":"0 0 24 24","fill":"transparent","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","color":""}},[_c('path',{attrs:{"d":"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"}}),_c('polyline',{attrs:{"points":"17 8 12 3 7 8"}}),_c('line',{attrs:{"x1":"12","y1":"3","x2":"12","y2":"15"}})]),_c('div',{staticClass:"subtitle"},[_c('span',[_vm._v(" Drop File or ")]),_c('span',{staticStyle:{"color":"#eb2027"}},[_vm._v(" Browse")])])])]),(_vm.submitted && !_vm.$v.addMedia.file.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" File is required ")]):_vm._e()],1),_vm._m(1),_vm._m(2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("Add Media Library")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb d-none-sm"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("Media ")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" Add Media Library ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pt-1"},[_c('small',[_vm._v("Maximum upload file size 10 MB. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pt-3"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn btn-add btn-primary"},[_vm._v("Upload")])])])
}]

export { render, staticRenderFns }