<template>
  <div class="add-media-library">
    <div class="page-header">
      <h3 class="page-title">Add Media Library</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb d-none-sm">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Media </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Add Media Library
          </li>
        </ol>
      </nav>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="processFiles">
          <div class="row">
            <div class="col-12 col-md-7 col-lg-5 mb-3">
              <select
                class="custom-select form-control"
                v-model="addMedia.folder"
                id="folder"
                name="folder"
                :class="{
                  'is-invalid': submitted && $v.addMedia.folder.$error,
                }"
              >
                <option value="" selected>Select Folder</option>
                <option value="pages">Pages</option>
                <option value="products">Products</option>
                <option value="brands">Brands</option>
              </select>
              <div
                v-if="submitted && !$v.addMedia.folder.required"
                class="invalid-feedback"
              >
                Folder is required
              </div>
            </div>
            <div class="col-md-12">
              <vue-dropzone
                ref="dropzone"
                id="dropzone"
                v-model="addMedia.file"
                @vdropzone-sending="sendImages"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                :class="{
                  'is-invalid': submitted && $v.addMedia.file.$error,
                }"
              >
                <div class="dropzone-custom-content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="transparent"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-upload"
                    color=""
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  <div class="subtitle">
                    <span> Drop File or </span>
                    <span style="color: #eb2027"> Browse</span>
                  </div>
                </div>
              </vue-dropzone>
              <div
                v-if="submitted && !$v.addMedia.file.required"
                class="invalid-feedback"
              >
                File is required
              </div>
            </div>
            <div class="col-12 pt-1">
              <small>Maximum upload file size 10 MB. </small>
            </div>
            <div class="col-12 pt-3">
              <div class="float-right">
                <button class="btn btn btn-add btn-primary">Upload</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
export default {
  name: "add-media-library",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      addMedia: {
        folder: "",
        file: "",
      },
      submitted: false,
    };
  },
  validations: {
    addMedia: {
      folder: { required },
      file: {},
    },
  },
  methods: {
    sendImages(file, xhr, formData) {
      let folder = this.addMedia.folder;
      xhr.setRequestHeader("Header", "");
      formData.append("folder", folder);
    },
    processFiles() {
      this.$refs.dropzone.processQueue();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
  },
};
</script>